import React from "react";
import "../src/styles/App.css";
import "@cloudscape-design/global-styles/index.css";
import TopNavigation from "./components/navigation/TopNavigation.tsx";
import ApplicationRoutes from "./router/ApplicationRoutes.tsx";
import Footer from "./components/navigation/Footer.tsx";

export default function App() {
  return (
    <div>
      <nav>
        <TopNavigation />
      </nav>
      <ApplicationRoutes />
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

import React from "react";
import { Box } from "@cloudscape-design/components";
import TopNavigation from "@cloudscape-design/components/top-navigation";
import { DarkModeToggle } from "../dark-mode/DarkModeToggle.tsx";

export default () => {
  return (
    <div id="top-nav-bar">
      <Box>
        <TopNavigation
          identity={{ href: "/", title: "Amit Ojha" }}
          utilities={[
            {
              type: "button",
              text: "Papershelf",
              href: "/papershelf",
              external: false,
            },
            {
              type: "button",
              text: "Blogs",
              href: "/blogs",
              external: false,
            },
            {
              type: "button",
              text: "Collaborate",
              href: "/collaborate",
              external: false,
            },
            {
              type: "button",
              // @ts-ignore
              text: <DarkModeToggle />,
              ariaLabel: "Toggle dark mode",
              disableUtilityCollapse: true,
            },
          ]}
        />
      </Box>
    </div>
  );
};

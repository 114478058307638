import React from "react";
import { Box, Container, SpaceBetween } from "@cloudscape-design/components";
import profileImage from "../resources/profileImage.jpg";

const Home = () => {
  return (
    <div id="home">
      <Container
        media={{
          content: <img src={profileImage} alt="placeholder" />,
          position: "side",
          width: "33%",
        }}
      >
        <SpaceBetween direction="vertical" size="s">
          <SpaceBetween direction="vertical" size="xxs">
            <Box variant="h2">
              <div id="intro-heading">Hey, I am Amit!</div>
            </Box>
            <Box variant="small">
              <div id="intro-supplement-text">
                A lifelong learner, always pushing the boundaries of
                engineering.
              </div>
            </Box>
          </SpaceBetween>
          <Box variant="p">
            <div id="intro">
              <p>
                I am a software engineer, who is passionate about Advanced
                Algorithms, System Design, and Language Internals.
              </p>
              <p>
                I have a total of over 13 years of experience in the insurance,
                retail banking, and e-commerce domains, taking products and
                teams from 0 to 1 and beyond.
              </p>
              <p>
                I keep diving deep into engineering details and share my
                learnings through by blogs.
              </p>
            </div>
          </Box>
        </SpaceBetween>
      </Container>
    </div>
  );
};

export default Home;

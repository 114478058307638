import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Blogs from "../pages/Blogs.tsx";
import Collaborate from "../pages/Collaborate.tsx";
import Home from "../pages/Home.tsx";
import Papershelf from "../pages/Papershelf.tsx";

const ApplicationRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/papershelf" element={<Papershelf />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/collaborate" element={<Collaborate />} />
      </Routes>
    </BrowserRouter>
  );
};

export default ApplicationRoutes;

import React, { useState, useEffect } from "react";
import { Toggle } from "@cloudscape-design/components";
import { applyMode, Mode } from "@cloudscape-design/global-styles";

const DARK_MODE_STORAGE_KEY = "darkMode";

export const DarkModeToggle = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem(DARK_MODE_STORAGE_KEY) || "false"
  );

  useEffect(() => {
    localStorage.setItem(DARK_MODE_STORAGE_KEY, isDarkMode);
    applyMode(isDarkMode === "true" ? Mode.Dark : Mode.Light);
  }, [isDarkMode]);

  return (
    <Toggle
      onChange={({ detail }) => {
        setIsDarkMode(detail.checked ? "true" : "false");
      }}
      checked={isDarkMode === "true"}
    >
      Dark Mode
    </Toggle>
  );
};
